/** @format */

import { useState, useEffect, useRef, useLayoutEffect, useContext } from "react";
import { Container as ContainerBase, Draggable as DraggableBase, DropResult } from "react-smooth-dnd";
import { InView } from "react-intersection-observer";
import { IoArrowBackCircle, IoCloseSharp, IoCopyOutline, IoHeart, IoHeartOutline, IoMail, IoSaveOutline, IoSearch, IoTrashOutline } from "react-icons/io5";

import { AiOutlinePrinter } from "react-icons/ai";
import CreatableSelect from "react-select/creatable";

import { IBasicTag, ICustomer, IExercise, IPlan, IPlanExercise } from "../../Api/Interfaces";
import { UseLoading } from "../../Shared/Loading/UseLoading";
import { ReactSelectable } from "../../Shared/ISelectable";
import { UseMultiTagSelect } from "../../Shared/UseMultiTagSelect";
import { UseSearch } from "../../Shared/Search/UseSearch";
import "./PlanEdit.css";
import { ExerciseService } from "../../Api/Exercise";
import { CustomerService } from "../../Api/Customer";
import { PlanService } from "../../Api/Plan";
import { PlanView } from "./PlanView/PlanView";
import { FileRole } from "../../Api/FileRoleEnum";
import { AddedExercise } from "./AddedExercise/AddedExercise";
import { useModal } from "../../Shared/Modal/UseModal";
import { PlanPrint } from "../PlanPrint/PlanPrint";
import { PlanShare } from "../PlanShare/PlanShare";
import { PlanExerciseEdit } from "../PlanExerciseEdit/PlanExerciseEdit";
import { ActiveModal } from "../../Shared/Modal/ActiveModal";
import { PlanRenderService } from "../../Api/PlanRender";
import { GenericSearchEngine } from "../../Shared/Search/GenericSearchEngine";
import { Link, useNavigate, useParams } from "react-router-dom";
import { StateManContext } from "../MainPage/StateMan";
import { Startup } from "../Startup/Startup";
import { CreateCustomerModal } from "./CreateCustomerModal/CreateCustomerModal";
import { FaUserPlus } from "react-icons/fa6";
import { TagMenu } from "./TagMenu/TagMenu";
import { useReactSet } from "../../Shared/UseReactSet";

const Container: any = ContainerBase;
const Draggable: any = DraggableBase;

export interface IPlanEditProps {
    selectedPlan?: IPlan | null;
    setSelectedPlan?: (value: IPlan | null) => any;
    goBack?: () => void;
    selectedCustomer?: ICustomer;
}

export interface IExerciseWithImage extends IExercise {
    mainImageUrl: string;
}

export interface IPlanExerciseWithMedia extends IPlanExercise {
    name: string;
    mainImageUrl: string;
    mainVideoUrl: string;
}

export const PlanEdit = () => {
    const [allExercises, setAllExercises] = useState<IExerciseWithImage[]>([]);
    const [selectedExercises, setSelectedExercises] = useState<IPlanExerciseWithMedia[]>([]);
    const [idCounter, setIdCounter] = useState(-1);
    const [searchText, setSearchText] = useState("");
    const [selectedPlan, setSelectedPlan] = useState<IPlan>();
    const [isCreatingCustomer, setIsCreatingCustomer] = useState(false);
    const selectedTags = useReactSet<IBasicTag>();

    const [name, setName] = useState("");
    const [isFavorite, setIsFavorite] = useState(false);
    const useActionLoader = UseLoading();
    const tagSelect = UseMultiTagSelect();
    const [showOnlyFavorites, setShowOnlyFavorites] = useState(false);

    const params = useParams();
    const navigate = useNavigate();
    const stateMan = useContext(StateManContext);

    const [allCustomers, setAllCustomers] = useState<ICustomer[]>([]);
    const [selectedCustomer, setSelectedCustomer] = useState<ReactSelectable | null>(null);
    const [editPlanItemId, setEditPlanItemId] = useState<number | null>(null);

    const [loadedExercises, setLoadedExercises] = useState<number>(20);

    const headerRef = useRef<HTMLHeadElement>(document.createElement("header"));
    const dropBoxRef = useRef<HTMLDivElement>(document.createElement("div"));

    const previewExerciseModal = useModal("Forhåndsvis øvelse");

    const previewPlanModal = useModal("Forhåndsvis plan");

    const planEmailSharingModal = useModal("Dele plan på epost", true);

    const printPlanModal = useModal("Velg størrelse og skriv ut plan", true);

    useLayoutEffect(() => {
        initialLoadAsync();
    }, []);

    const goBack = async () => {
        navigate(-1);
    };

    const initialLoadAsync = async () => {
        useActionLoader.startLoading();
        await loadAllCustomersAsync();

        const customerId = parseInt(params.customerId ?? "");
        if (!isNaN(customerId)) {
            const customer = await CustomerService.GetAsync(customerId);
            if (customer) {
                setSelectedCustomer({ label: customer.name, value: customer.id.toString() });
            }
        }

        const exercises = await loadAllExercisesAsync();

        if (params.planId && !isNaN(parseInt(params.planId))) {
            const plan = await PlanService.GetAsync(parseInt(params.planId));
            if (plan) {
                await loadPlanDataAsync(plan, exercises);
            }
        }

        useActionLoader.stopLoading();
    };

    const loadAllCustomersAsync = async () => {
        const items = await CustomerService.ListAsync();
        setAllCustomers(items);
    };

    const loadAllExercisesAsync = async (): Promise<IExerciseWithImage[]> => {
        const items = await ExerciseService.ListAsync();
        if (!items) return [];

        const newItems: IExerciseWithImage[] = [];
        for (let i = 0; i < items.length; i++) {
            const element = items[i];

            let mainImageUrl = "";
            if (element.files && element.files.length > 0) {
                const fileHolder = element.files.find((f) => f.type === "image");
                if (fileHolder) {
                    mainImageUrl = fileHolder.publicUrl;
                }
            }
            newItems.push({ ...element, mainImageUrl });
        }

        setAllExercises(newItems);
        return newItems;
    };

    const loadPlanDataAsync = async (plan: IPlan, localAllExercises: IExerciseWithImage[]) => {
        if (!plan) return;

        setName(plan.name);
        setIsFavorite(plan.favorite);
        setSelectedPlan(plan);

        if (plan.customerId) {
            const currentCustomer = await CustomerService.GetAsync(plan.customerId);
            if (currentCustomer) {
                setSelectedCustomer({ label: currentCustomer.name, value: currentCustomer.id.toString() });
            } else {
                setSelectedCustomer(null);
            }
        } else {
            setSelectedCustomer(null);
        }

        const currentExercises = await PlanService.GetPlanExercises(plan.id);

        const exercises: IPlanExerciseWithMedia[] = currentExercises.map((e) => {
            const exercise = localAllExercises.find((ei) => ei.id === e.exerciseId);
            const listVideo = exercise ? exercise.files.find((f) => f.role === FileRole.listPreviewVideo)?.publicUrl ?? "" : "";

            return {
                exerciseId: e.exerciseId,
                id: e.id,
                planId: e.planId,
                ordering: e.ordering,

                reps: e.reps,
                sets: e.sets,
                weight: e.weight,
                comment: e.comment,

                mainImageUrl: exercise?.mainImageUrl ?? "",
                mainVideoUrl: listVideo,
                name: exercise?.name ?? "",
            };
        });

        setSelectedExercises(exercises);
    };

    const setFavoriteAsync = async (exerciseId: number, favorite: boolean) => {
        const oldExercises = [...allExercises];
        const newExercises = [...allExercises];
        const index = newExercises.findIndex((e) => e.id === exerciseId);
        newExercises[index].favorite = favorite;
        setAllExercises(newExercises);

        await ExerciseService.SetFavoriteStatusAsync(exerciseId, favorite).catch(() => {
            alert("Klarte ikke sette favoritt, sjekk internett tilkoblingen din og prøv igjen");
            setAllExercises(oldExercises);
        });
    };

    const newPlanExercise = (exerciseId: number): IPlanExerciseWithMedia | null => {
        const currentExercise = allExercises.find((e) => e.id === exerciseId);

        const listVideo = currentExercise ? currentExercise.files.find((f) => f.role === FileRole.listPreviewVideo)?.publicUrl ?? "" : "";
        const data: IPlanExerciseWithMedia = {
            id: idCounter,
            name: currentExercise?.name ?? "",
            exerciseId,
            planId: selectedPlan ? selectedPlan.id : -1,
            ordering: 0,

            comment: currentExercise ? currentExercise.description : "",
            reps: "",
            sets: "",
            weight: "",
            mainImageUrl: currentExercise?.mainImageUrl ?? "",
            mainVideoUrl: listVideo,
        };

        setIdCounter(idCounter - 1);

        return data;
    };

    const applyDrag = (arr: any, dragResult: DropResult): any => {
        const { removedIndex, addedIndex, payload } = dragResult;
        if (removedIndex === null && addedIndex === null) return arr;

        const result = [...arr];
        const isAdding = removedIndex === null;

        if (removedIndex !== null) {
            result.splice(removedIndex, 1);
        }

        if (addedIndex !== null) {
            result.splice(addedIndex, 0, isAdding ? newPlanExercise(payload.id) : payload);
        }

        return result;
    };

    const removeAddedExercise = (exerciseId: number) => {
        setSelectedExercises(selectedExercises.filter((e) => e.id !== exerciseId));
    };

    const addExercise = (exerciseId: number) => {
        const addExercise = newPlanExercise(exerciseId);

        if (addExercise) {
            setSelectedExercises([...selectedExercises, addExercise]);
        }
    };

    const infoChangeAddedExercise = (exerciseId: number, infoType: string, info: string) => {
        const copy: any = [...selectedExercises];
        const index = copy.findIndex((e: any) => e.id === exerciseId);
        copy[index][infoType] = info;
        setSelectedExercises(copy);
    };

    const copyExerciseInfoToAllExercises = (exerciseId: number) => {
        const exercise = selectedExercises.find((e) => e.id === exerciseId);
        if (!exercise) return;

        const hasOtherExercisedata = selectedExercises.some(
            (e) => e.id !== exerciseId && (e.reps.trim().length > 0 || e.sets.trim().length > 0 || e.weight.trim().length > 0)
        );

        if (hasOtherExercisedata) {
            const shouldPerformAction = window.confirm("Andre vedlagte øvelser har informasjon fra før. Vil du kopiere data til alle øvelser?");
            if (!shouldPerformAction) return;
        }

        const copy = [...selectedExercises];

        for (let i = 0; i < copy.length; i++) {
            const element = copy[i];
            element.reps = exercise.reps;
            element.sets = exercise.sets;
            element.weight = exercise.weight;
        }

        setSelectedExercises(copy);
    };

    const saveAsync = async (saveToNewItem: boolean, close: boolean, newName?: string): Promise<number | undefined> => {
        useActionLoader.startLoading();
        let customerId: number | undefined = undefined;
        if (selectedCustomer) {
            customerId = parseInt(selectedCustomer.value);
        }

        const dateNowText = new Date().toLocaleString("en-GB").toString();
        let planName = name && name.length > 0 ? name : dateNowText;
        if (newName) planName = newName;
        setName(planName);

        let planId: number;
        if (!saveToNewItem && selectedPlan) {
            await PlanService.UpdateAsync(selectedPlan.id, planName, isFavorite, customerId);
            planId = selectedPlan.id;
        } else {
            const response = await PlanService.CreateAsync(planName, isFavorite, customerId);
            if (!response) {
                useActionLoader.stopLoading();
                return;
            }

            planId = response;
        }

        await PlanService.SavePlanExercises(planId, selectedExercises);
        await stateMan?.updateAsync();
        useActionLoader.stopLoading();
        if (close) {
            goBack();
        }
        return planId;
    };

    const deletePlanAsync = async () => {
        if (selectedPlan) {
            const confirmation = window.confirm("Sikker på at du vil slette planen?");
            if (confirmation) {
                await PlanService.DeleteAsync(selectedPlan.id);
                goBack();
            }
        } else {
            goBack();
        }
    };

    const SaveGetPlanAsync = async (): Promise<IPlan | null> => {
        let currentPlan = selectedPlan;
        const id = await saveAsync(false, false);
        if (!id) return null;
        const plan = await PlanService.GetAsync(id);
        if (!plan) return null;
        currentPlan = plan;
        setSelectedPlan(currentPlan);

        return currentPlan;
    };

    const PrintPlanAsync = async () => {
        const plan = await SaveGetPlanAsync();
        if (!plan) return;

        printPlanModal.open(<PlanPrint plan={plan} />, { width: "100%", height: "100%", maxWidth: 500, maxHeight: 500, overflowY: "initial" });
    };

    const SharePlanAsync = async () => {
        const plan = await SaveGetPlanAsync();
        if (!plan) return;

        planEmailSharingModal.open(<PlanShare plan={plan} />, { width: "100%", height: "100%", maxWidth: 800, maxHeight: 700, overflowY: "initial" });
    };

    const PreviewExerciseAsync = async (e: IExerciseWithImage) => {
        const content = await PlanRenderService.GetUnfinishedExerciseAsync(e.id, {
            reps: "",
            sets: "",
            weight: "",
            comment: e.description,
        });

        if (!content) return alert("Forhåndsvisning feilet. Prøv igjen senere");
        const component = <iframe title="preview" className="previewIframe" srcDoc={content} />;
        previewExerciseModal.open(component);
    };

    const clearPlan = () => {
        setSelectedExercises([]);
    };

    const createCustomer = async (name: string) => {
        const customerId = await CustomerService.CreateAsync(name, "");
        if (!customerId) {
            alert("Klarte ikke opprette kunde");
            return;
        }

        await loadAllCustomersAsync();
        setSelectedCustomer({ label: name, value: String(customerId) });
    };

    useEffect(() => {
        const list = document.querySelector<HTMLDivElement>(".PlanEdit .leftGroup .smooth-dnd-container.vertical");
        if (list) {
            list.scroll({ top: 0 });
        }
    }, [searchText]);

    const setSelectedCustomerFromModal = async (customerId: number) => {
        const customer = await CustomerService.GetAsync(customerId);

        if (customer) {
            setSelectedCustomer({ label: customer.name, value: String(customer.id) });
        }
    };

    /* Probably a better way to find relevant plans based on multiple tags. Feel free to rewrite if you have a better idea */
    let tagSelectIds: number[] = [];
    if (selectedTags.any()) {
        tagSelectIds = Array.from(selectedTags.state).map((ts) => ts.id);
    }

    const searchEngine = new GenericSearchEngine<IExerciseWithImage>({
        items: allExercises,
        fieldsToSearch: [
            { name: "name", weight: 4 },
            { name: "searchWords", weight: 1 },
        ],
    });
    const searchItems = searchEngine.Search(searchText);
    let viewableExercises: IExerciseWithImage[] = searchItems;
    if (showOnlyFavorites) viewableExercises = viewableExercises.filter((e) => e.favorite);

    viewableExercises = viewableExercises.filter((e) => {
        if (!tagSelect.items) return true;
        if (tagSelectIds.length === 0) return true;

        const exerciseTagIds = e.tags.map((t) => t.id);
        for (let i = 0; i < tagSelectIds.length; i++) {
            const tagId = tagSelectIds[i];
            if (!exerciseTagIds.includes(tagId)) {
                return false;
            }
        }

        return true;
    });

    const selectedItemExerciseId = selectedExercises.find((e) => e.id === editPlanItemId)?.exerciseId ?? 0;
    const selectedItemExercise = editPlanItemId == null ? null : allExercises.find((e) => e.id === selectedItemExerciseId) ?? null;

    return (
        <div className={`PlanEdit`}>
            <Startup />
            {previewExerciseModal.component}
            {planEmailSharingModal.component}
            {previewPlanModal.component}
            {printPlanModal.component}

            {isCreatingCustomer && <CreateCustomerModal onSave={setSelectedCustomerFromModal} exitModal={() => setIsCreatingCustomer(false)} />}

            <ActiveModal
                onClose={() => {
                    setEditPlanItemId(null);
                }}
                isOpen={editPlanItemId !== null}
                title={selectedItemExercise?.name}
            >
                <PlanExerciseEdit
                    exercise={selectedItemExercise}
                    planItemId={editPlanItemId ?? 0}
                    planExercises={selectedExercises}
                    setPlanExercises={setSelectedExercises}
                />
            </ActiveModal>

            <header ref={headerRef}>
                <div className="title">
                    <Link className="branding" to="/">
                        <img className="brandingImg" src={process.env.PUBLIC_URL + "/fulltexticon.png"} />
                    </Link>

                    {useActionLoader.loadingComponent}
                </div>

                <div className="disabledOverlay" style={{ opacity: useActionLoader.isLoading ? "1" : "0" }}></div>

                <div className={`btnGroup`}>
                    <button onClick={() => goBack()}>
                        <IoArrowBackCircle />
                        Avbryt
                    </button>

                    <button onClick={() => deletePlanAsync()}>
                        <IoTrashOutline style={{ color: "red" }} />
                        Slett
                    </button>

                    <button onClick={() => clearPlan()}>
                        <IoCloseSharp style={{ color: "red" }} />
                        Nullstille program
                    </button>

                    <button onClick={() => SharePlanAsync()}>
                        <IoMail />
                        Send på e-post
                    </button>

                    <button onClick={() => PrintPlanAsync()}>
                        <AiOutlinePrinter />
                        Print
                    </button>

                    <button className={isFavorite ? "favoriteBtnSelected" : ""} onClick={() => setIsFavorite(!isFavorite)}>
                        {isFavorite ? <IoHeart /> : <IoHeartOutline />}
                        Merk som favoritt
                    </button>

                    <button
                        onClick={() => {
                            const value = prompt("Kopier denne planen. Nytt navn:", name);
                            if (!value) return;
                            saveAsync(true, true, value);
                        }}
                    >
                        <IoCopyOutline />
                        Kopier
                    </button>

                    <button onClick={() => saveAsync(false, true)}>
                        <IoSaveOutline />
                        Lagre
                    </button>
                </div>
            </header>

            <div className="mainGroup" style={{ height: `calc(100vh - ${headerRef.current.offsetHeight + 20}px)` }}>
                <div className="topGroup">
                    <input
                        className="nameInput"
                        type="text"
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                        maxLength={50}
                        placeholder="Navn på program"
                    />

                    <div className="select" style={{ zIndex: 8 }}>
                        <CreatableSelect
                            formatCreateLabel={(input) => `Opprett kunde "${input}"`}
                            isClearable={true}
                            onCreateOption={createCustomer}
                            styles={{ container: (provided) => ({ ...provided, width: "100%", textAlign: "left" }) }}
                            placeholder="Ingen kunde valgt"
                            name="customers"
                            value={selectedCustomer}
                            options={stateMan?.customers.map((c) => ({
                                label: c.name,
                                value: c.id.toString(),
                            }))}
                            onChange={(newValue: any) => setSelectedCustomer(newValue)}
                        />
                    </div>

                    <button className="createCustomerBtn" onClick={() => setIsCreatingCustomer(true)}>
                        <FaUserPlus />
                        Ny kunde
                    </button>
                </div>

                <div className="leftRightGroup">
                    <div className="leftGroup">
                        <div className="filterGroup">
                            <div className="filterLeft">
                                <div className="UseSearch">
                                    <IoSearch />
                                    <input
                                        autoFocus
                                        placeholder="Søk på øvelser"
                                        value={searchText}
                                        onChange={(e) => {
                                            setSearchText(e.target.value);
                                        }}
                                    />
                                </div>

                                <TagMenu selectedTags={selectedTags} />
                            </div>

                            <button
                                className={`favoritesBtn ${showOnlyFavorites ? "favoritesBtnSelected" : ""}`}
                                onClick={() => setShowOnlyFavorites(!showOnlyFavorites)}
                            >
                                {showOnlyFavorites ? <IoHeart size={18} color="var(--favorite-color)" /> : <IoHeartOutline size={18} />}
                                Favoritter
                            </button>
                        </div>

                        {(viewableExercises.length === 0 && !useActionLoader.isLoading) && <p className="noExercisesFound">Ingen øvelser funnet på valgte filtre</p>}
                        {viewableExercises.length === 0 && useActionLoader.isLoading && (
                            useActionLoader.loadingComponent
                        )}

                        <Container
                            orientation="vertical"
                            groupName="1"
                            behaviour="copy"
                            getChildPayload={(i: number) => viewableExercises[i]}
                            onDrop={(e: DropResult) => setAllExercises(applyDrag(viewableExercises, e))}
                        >
                            {viewableExercises.map((e, i) => (
                                <Draggable key={i}>
                                    <InView
                                        onChange={(inView) => {
                                            if (inView && i > loadedExercises - 20) {
                                                setLoadedExercises(loadedExercises + 20);
                                            }
                                        }}
                                    >
                                        {({ inView, ref, entry }) => (
                                            <PlanView
                                                index={i}
                                                refCustom={ref}
                                                loadImage={i <= loadedExercises}
                                                onDoubleClick={() => addExercise(e.id)}
                                                onInfoClick={() => PreviewExerciseAsync(e)}
                                                setFavorite={setFavoriteAsync}
                                                exercise={e}
                                                key={i}
                                            />
                                        )}
                                    </InView>
                                </Draggable>
                            ))}
                        </Container>
                    </div>

                    <div ref={dropBoxRef} className={`rightGroup dropArea ${selectedExercises.length === 0 ? "dropAreaEmpty" : ""}`}>

                        {(selectedExercises.length === 0 && !useActionLoader.isLoading) && (
                            <p className="emptyText">Dra øvelser hit</p>
                        )}

                        {(selectedExercises.length === 0 && useActionLoader.isLoading) && (
                            useActionLoader.loadingComponent
                        )}

                        <Container
                            onDragEnter={() => {
                                dropBoxRef.current.classList.add("dropElement");
                            }}
                            onDragLeave={() => {
                                dropBoxRef.current.classList.remove("dropElement");
                            }}
                            onDragEnd={() => {
                                dropBoxRef.current.classList.remove("dropElement");
                            }}
                            style={{ height: "100%", width: "100%" }}
                            groupName="1"
                            getChildPayload={(i: number) => selectedExercises[i]}
                            onDrop={(e: DropResult) => setSelectedExercises(applyDrag(selectedExercises, e))}
                        >
                            {selectedExercises.map((e, i) => (
                                <Draggable key={i}>
                                    <AddedExercise
                                        isFirstItem={i === 0}
                                        onInfoClick={() => {
                                            const exercise = allExercises.find((ex) => ex.id === e.exerciseId);
                                            if (!exercise) return alert("Error i forhåndsvisning");
                                            PreviewExerciseAsync(exercise);
                                        }}
                                        copyInfoClick={copyExerciseInfoToAllExercises}
                                        onInfoChange={infoChangeAddedExercise}
                                        onClick={(id) => setEditPlanItemId(id)}
                                        onRemove={removeAddedExercise}
                                        exercise={e}
                                    />
                                </Draggable>
                            ))}
                        </Container>
                    </div>
                </div>
            </div>
        </div>
    );
};
