/** @format */

import React, { useEffect, useRef, useState } from "react";
import { GiWeightLiftingUp } from "react-icons/gi";
import { ExerciseService, IFileCreate } from "../../Api/Exercise";
import CreatableSelect from "react-select/creatable";
import { OnChangeValue } from "react-select";
import "./CreateExercise.css";
import { TagService } from "../../Api/Tag";
import { FileService, FileType } from "../../Api/File";
import { ReactSelectable } from "../../Shared/ISelectable";
import { UseLoading } from "../../Shared/Loading/UseLoading";
import { FileRole } from "../../Api/FileRoleEnum";
import { AiFillDelete } from "react-icons/ai";
import { IoSearch } from "react-icons/io5";

interface ICreateExerciseProps {
    id?: number;
    modal?: any;
}

interface IFileInfo extends IFileCreate {
    file: File | null;
}

export const CreateExercise = (props: ICreateExerciseProps) => {
    const [name, setName] = useState("");
    const [alternativeSearchWords, setAlternativeSearchWords] = useState<string>("");
    const [description, setDescription] = useState("");
    const [files, setFiles] = useState<IFileInfo[]>([]);
    const [tags, setTags] = useState<ReactSelectable[]>([]);
    const [allSelectedTags, setAllSelectedTags] = useState<ReactSelectable[]>([]);
    const [newFileId, setNewFileId] = useState(-1);
    const fileInputRef = useRef(null);
    const useActionLoader = UseLoading();

    const loadTags = async () => {
        const tags = await TagService.ListAsync();
        const items: ReactSelectable[] = [];
        tags.forEach((item) => {
            items.push({
                label: item.name,
                value: item.id.toString(),
            });
        });

        setTags(items);
    };

    useEffect(() => {
        loadTags();
    }, []);

    const loadExercise = async () => {
        if (!props.id || (props.id && props.id < 1)) return;

        await loadTags();
        const exercise = await ExerciseService.GetAsync(props.id);
        if (!exercise) return;
        setName(exercise.name);
        setDescription(exercise.description);

        const allFiles: IFileInfo[] = [];
        exercise.files.forEach((f) => {
            allFiles.push({
                file: null,
                name: f.fileName,
                role: f.role,
                fileId: f.fileId,
                ordering: f.ordering,
            });
        });

        setFiles(allFiles);

        setAlternativeSearchWords(exercise.searchWords.join(", "));

        const tagItems: ReactSelectable[] = [];
        exercise.tags.forEach((tag) => {
            tagItems.push({
                label: tag.name,
                value: tag.id.toString(),
            });
        });

        setAllSelectedTags(tagItems);
    };

    useEffect(() => {
        if (props.id && props.id > 0) {
            loadExercise();
        }
    }, [props.id]);

    const textFileProcessAsync = async (file: File) => {
        const type = FileService.GetFileType(file.name);
        if (type != FileType.text) return;
        const checkFileName = FileService.GetFileNameWithoutExtension(file.name).trim().toLowerCase();

        const nameNames = ["name", "navn", "title"];
        const descriptionNames = ["description", "beskrivelse", "forklaring"];
        if (nameNames.includes(checkFileName)) {
            const text = await file.text();
            setName(text);
        }
        if (descriptionNames.includes(checkFileName)) {
            const text = await file.text();
            setDescription(text);
        }
    };

    const handleFileChangeAsync = async (event: React.ChangeEvent<HTMLInputElement>) => {
        if (!event.target.files) return;

        const allFiles = event.target.files;

        const newFiles: IFileInfo[] = [];
        let fileId = newFileId;
        for (let i = 0; i < allFiles.length; i++) {
            const file = allFiles.item(i);
            if (file === null) continue;

            const type = FileService.GetFileType(file.name);
            await textFileProcessAsync(file);

            const allOrderingItems = files.concat(newFiles).filter(f => f.role != FileRole.listPreviewImage && f.role != FileRole.listPreviewVideo);
            const allImages = allOrderingItems.filter((f) => f.role === FileRole.image);
            const allVideoes = allOrderingItems.filter((f) => f.role === FileRole.video);

            if (type === FileType.image) {
                newFiles.push({
                    file: file,
                    name: file.name,
                    role: FileRole.image,
                    fileId: String(fileId),
                    ordering: allOrderingItems.length + 1,
                });

                fileId -= 1;

                if (allImages.length === 0) {
                    newFiles.push({
                        file: file,
                        name: file.name,
                        role: FileRole.listPreviewImage,
                        fileId: String(fileId),
                        ordering: 1,
                    });
                }
            }

            if (type === FileType.video) {
                newFiles.push({
                    file: file,
                    name: file.name,
                    role: FileRole.video,
                    fileId: String(fileId),
                    ordering: allOrderingItems.length + 1,
                });

                fileId -= 1;

                if (allVideoes.length === 0) {
                    newFiles.push({
                        file: file,
                        name: file.name,
                        role: FileRole.listPreviewVideo,
                        fileId: String(fileId),
                        ordering: 1,
                    });
                }
            }

            fileId -= 1;
        }

        setNewFileId(fileId);
        setFiles(files.concat(newFiles));
        event.target.value = "";
    };

    const handleFileDelete = (id: string) => {
        setFiles([...files.filter((f) => f.fileId != id)]);
    };

    const saveAsync = async () => {
        const previewImageCount = files.filter(f => f.role === FileRole.listPreviewImage).length
        const previewVideoCount = files.filter(f => f.role === FileRole.listPreviewVideo).length
        const imageCount = files.filter(f => f.role === FileRole.image).length
        
        if (previewImageCount !== 1) {
            return alert("Feil: det må være en og bare en fil merket listPreviewImage")
        }

        if (previewVideoCount !== 1) {
            return alert("Feil: det må være en og bare en fil merket listPreviewVideo")
        }

        const normalItemOrdering = files.filter(f => f.role === FileRole.image || f.role === FileRole.video).map(f => f.ordering)
        const isDuplicateOrdering = (new Set(normalItemOrdering).size !== normalItemOrdering.length);
        if (isDuplicateOrdering) {
            return alert("Feil: det er flere filer med samme rekkefølge")
        }
        
        const tagIds: number[] = [];
        for (let i = 0; i < allSelectedTags.length; i++) {
            const tag = allSelectedTags[i];
            if (isNaN(parseInt(tag.value))) {
                const newTagId = await TagService.CreateAsync(tag.label);
                if (newTagId) tagIds.push(newTagId);
            } else {
                tagIds.push(parseInt(tag.value));
            }
        }

        const sortedFiles = files.sort((a, b) => a.ordering - b.ordering);
        for (let i = 0; i < files.length; i++) {
            const file = sortedFiles[i];

            // Only uploads new files (only new files have file content)
            if (file.file) {
                const newFileId = await FileService.uploadAsync(file.file, file.role);
                if (newFileId) {
                    file.fileId = newFileId;
                } else {
                    console.error(`Klarte ikke lagre filen ${file.name}`);
                }
            }
        }

        const searchWords = alternativeSearchWords.split(",").map(w => w.trim()).filter(w => w.length > 0);

        if (props.id && props.id > 0) {
            const success = await ExerciseService.EditAsync(name, description, tagIds, [...sortedFiles], searchWords, props.id);
            if (!success) {
                alert("Kunne ikke lagre")
                return;
            }
        } else {
            const result = await ExerciseService.CreateAsync(name, description, tagIds, [...sortedFiles], searchWords);
            if (!result) {
                alert("Kunne ikke lagre")
                return;
            }
        }

        if (props.modal) {
            props.modal.close();
        }
    };

    const handleTagChangeAsync = (newValue: OnChangeValue<ReactSelectable, true>) => {
        // Doing stuff a little dynamic here, since the return value of react-select is a bit weird
        const data: any = newValue;
        setAllSelectedTags(data);
    };

    const handleFileOrdering = (id: string, ordering: number) => {
        const allFiles = files;
        const file = allFiles.find((f) => f.fileId === id);
        if (file) {
            file.ordering = ordering;
        }

        setFiles([...allFiles]);
    };

    const handleFileRole = (id: string, role: number) => {
        const allFiles = files;
        const file = allFiles.find((f) => f.fileId === id);
        if (file) {
            file.role = role;
        }

        setFiles([...allFiles]);
    };

    return (
        <div className="CreateExercise">
            <form
                onSubmit={async (e) => {
                    e.preventDefault();
                    useActionLoader.startLoading();
                    await saveAsync();
                    useActionLoader.stopLoading();
                }}
            >
                <div className="inputGroup">
                    <input
                        required
                        minLength={1}
                        maxLength={50}
                        autoFocus
                        type="text"
                        name="name"
                        placeholder="Navn"
                        value={name}
                        onChange={(e) => {
                            setName(e.target.value);
                        }}
                    />

                    <GiWeightLiftingUp />
                </div>

                <div className="inputGroup">
                    <input
                        minLength={0}
                        maxLength={10000}
                        type="text"
                        name="searchWords"
                        placeholder="Alternative søkeord (separer med komma)"
                        value={alternativeSearchWords}
                        onChange={(e) => {
                            const text = e.target.value;
                            setAlternativeSearchWords(text);
                        }}
                    />
                    <IoSearch />
                </div>



                <div className="selectWrapper">
                    <CreatableSelect
                        value={allSelectedTags}
                        formatCreateLabel={(input) => `Lag tag "${input}"`}
                        isMulti
                        onChange={handleTagChangeAsync}
                        options={tags}
                    />
                </div>

                <textarea
                    required
                    minLength={1}
                    maxLength={1000}
                    name="description"
                    placeholder="Beskrivelse"
                    value={description}
                    onChange={(e) => {
                        setDescription(e.target.value);
                    }}
                />

                <div className="fileSelector">
                    <input
                        ref={fileInputRef}
                        multiple
                        type="file"
                        onChange={handleFileChangeAsync}
                        name="files"
                        accept=".mp4,.mkv,.avi,.mov,.wmv,.flv,.webm,.m4v,.jpg,.jpeg,.png,.gif,.webp,.tiff,.tif,.bmp,.svg,.txt"
                    />

                    <ul>
                        {files.map((f) => (
                            <li key={f.fileId}>
                                <p>{f.name}</p>

                                <div className="selects">
                                    <select
                                        disabled={f.file === null}
                                        onChange={(e) => {
                                            e.preventDefault();
                                            handleFileRole(f.fileId, parseInt(e.target.value));
                                        }}
                                        value={f.role}
                                    >
                                        {Object.keys(FileRole)
                                            .filter((v) => isNaN(Number(v)))
                                            .map((key: any, index) => (
                                                <option key={index} value={FileRole[key]}>
                                                    {key}
                                                </option>
                                            ))}
                                    </select>

                                    <select
                                        disabled={f.role === FileRole.listPreviewImage || f.role === FileRole.listPreviewVideo}
                                        onChange={(e) => {
                                            e.preventDefault();
                                            handleFileOrdering(f.fileId, parseInt(e.target.value));
                                        }}
                                        value={f.ordering}
                                    >
                                        {files
                                            .filter(
                                                (f) =>
                                                    f.role != FileRole.listPreviewImage && f.role != FileRole.listPreviewVideo
                                            )
                                            .map((f, i) => (
                                                <option key={i + 1} value={i + 1}>
                                                    {i + 1}
                                                </option>
                                            ))}
                                    </select>

                                    <button
                                        onClick={(e) => {
                                            e.preventDefault();
                                            handleFileDelete(f.fileId);
                                        }}
                                    >
                                        <AiFillDelete />
                                    </button>
                                </div>
                            </li>
                        ))}
                    </ul>
                </div>

                <div className="submitWrapper">
                    {useActionLoader.loadingComponent}
                    <input
                        disabled={useActionLoader.isLoading}
                        type="submit"
                        className="Button Primary"
                        value={props.id && props.id > 0 ? "Oppdater øvelse" : "lage ny øvelse"}
                    />
                </div>
            </form>
        </div>
    );
};
